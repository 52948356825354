body {
  margin: 0;
  padding: 0;
  background-color: #1d1a1c;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #ececec;
}

a {
  color: #666;
}

#wrapper {
  width: 620px;
  margin: 0 auto;
}

header {
  margin: 2em 0 6em 0;
}

h1 {
  font-size: 3.4em;
  margin: 0;
}

.title,
.subtitle {
  color: #29abe2;
}

.line {
  font-size: 2.5em;
  overflow: hidden;
  margin: 0 0 1.2em 0;
}

.line .label {
  font-weight: 900;
  float: left;
  width: 300px;
  margin: 0 20px 0 0;
}

.line .money{

  float: left;
  width: 300px;
  font-weight: 900;

}

.line .money input {
  float: left;
  color: #29abe2;
}

.line .money input,
.line .money span.total {
  display: block;
  padding: 0;
  margin:0 0 0 14px;
  border: none;
  border-bottom: solid 2px #4d4d4d;
  font-size: 1em;
  font-weight: 900;
  background: none;
  width: 200px;
}

.line .money span {
  float: left;
}

input:focus {
  outline: none;
}

.lines .line:last-child {
  margin-bottom: 0;
}

p.notice {
  color: #808080;
  font-weight: 100;
}

p#fee_notice {
  margin-top: 0;
  margin-bottom: 8em;
}

p#ad_notice {
  margin-bottom: 4px;
}

.adspace {
  background: #fff;
  padding: 10px;
  min-height: 200px;
}

footer {
  margin: 6em 0 0 0;
  padding: 0 0 2em 0;
  color: #808080;
}

footer p {
  margin: .4em 0;
}
